<template>
    <HeaderComponent/>
    <main class="main">
        <router-view/>
    </main>
    <FooterComponent/>
</template>
<style lang="scss">
@import "scss/main.scss";
</style>
<script>
import {mapGetters, mapMutations} from "vuex";
export default {
    name: "App",
    components: {},
    data() {
        return {
            scrollPosition: 0,
        }
    },
    computed:{
        ...mapGetters([
            "getLang",
            "showForm",
        ]),
    },
    methods: {
        ...mapMutations([
            "setLang"
        ]),
        setScrollPosition() {
            this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        }
    },
    created() {
        let lang = localStorage.getItem('lang');
        this.setLang(lang || this.getLang)
    },
    mounted() {
        document.addEventListener('scroll', this.setScrollPosition)
    },
    beforeUnmount() {
        document.removeEventListener('scroll', this.setScrollPosition)
    }
}
</script>
