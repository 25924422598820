<template>
    <section class="personal">
        <div class="wrap">
            <h1 class="personal__title">{{'Наш персонал'}}</h1>
            <div class="personal__content">
                <swiper class="personal__slider" v-bind="swiperOptions">
                    <swiper-slide class="personal__slider-item" v-for="(p, i) in personal" :key="i">
                        <div class="personal__item">
                            <div class="personal__item-img">
                                <img :src="p.icon" v-if="false">
                            </div>
                            <div class="personal__item-content">
                                <h3 class="personal__item-title">{{p.name}}</h3>
                                <p class="personal__item-description">{{ p.description }}</p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <div class="personal__slider-button personal__slider-next"></div>
                <div class="personal__slider-button personal__slider-prev"></div>
            </div>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay'
import { Autoplay, Navigation } from 'swiper/modules';
export default {
    name: "PersonalComponent",
    components: {
        Swiper,
        SwiperSlide,
    },
    data () {
        return {
            personal: [
                {
                    icon: '',
                    name: 'Имя Фамилия',
                    description: 'О специалисте, кто он, услуги и подобное'
                },
                {
                    icon: '',
                    name: 'Имя Фамилия',
                    description: 'О специалисте, кто он, услуги и подобное'
                },
                {
                    icon: '',
                    name: 'Имя Фамилия',
                    description: 'О специалисте, кто он, услуги и подобное'
                },
                {
                    icon: '',
                    name: 'Имя Фамилия',
                    description: 'О специалисте, кто он, услуги и подобное'
                },
            ],
            swiperOptions:{
                centeredSlides: true,
                slidesPerView: 3,
                loopedSlides: 1,
                spaceBetween: 10,
                autoHeight: false,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false,
                },
                navigation: {
                    prevEl: '.personal__slider-prev',
                    nextEl: '.personal__slider-next',
                },
                breakpoints:{
                    0:{
                        slidesPerView: 1,
                        // centeredSlides: false,
                    },
                    1000:{
                        slidesPerView: 2,
                        centeredSlides: false,
                    },
                    1400:{
                        slidesPerView: 3
                    },
                    // 1380:{
                    //     slidesPerView: 4,
                    // },
                    // 1800:{
                    //     slidesPerView: 5
                    // }
                },
                loop: true,
                observe: true,
                observeParents: true,
                observeSlideChildren: true,
                speed:1000,
                modules:[Autoplay, Navigation]
            }
        }
    }
}
</script>

<style scoped>

</style>