<template>
    <breadcrumbsComponent/>
    <contactComponent/>
</template>

<script>
export default {
    name: "contactPage"
}
</script>

<style scoped>

</style>