import { createRouter, createWebHistory } from 'vue-router'
import mainPage from '@/views/mainPage.vue'
import servicesPage from '@/views/servicesPage.vue'
import aboutClinicPage from '@/views/aboutClinicPage.vue'
import contactPage from '@/views/contactPage.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'home',
    component: mainPage
  },
  {
    path: '/services',
    name: 'services',
    meta: {
      routeToParent: [
        {path: '/', title: 'Главная'},
        {path: '/services', title: 'Услуги'},
      ]
    },
    component: servicesPage
  },
  {
    path: '/about-the-clinic',
    name: 'aboutTheClinic',
    meta: {
      routeToParent: [
        {path: '/', title: 'Главная'},
        {path: '/about-the-clinic', title: 'О клинике'},
      ]
    },
    component: aboutClinicPage
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      routeToParent: [
        {path: '/', title: 'Главная'},
        {path: '/contact', title: 'Контакты'},
      ]
    },
    component: contactPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to) => {
  if(to.name == 'home' && to.path != '/'){
    return '/'
  }
  return true
})

export default router
