<template>
    <section class="contact-section">
            <div class="wrap">
                <h2 class="contact-section__title">
                    {{ 'Наши контакты' }}
                </h2>
            </div>
            <div class="contact-section__content">
                <div class="contact-section__content-map"></div>
                <div class="wrap">
                    <ContactWidget/>
                </div>
            </div>
    </section>
</template>

<script>
export default {
    name: "ContactComponent"
}
</script>

<style scoped>

</style>