import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)
app.use(store)
app.use(router)

//app component
import HeaderComponent from "@/components/HeaderComponent.vue";
app.component('HeaderComponent', HeaderComponent);
import FooterComponent from "@/components/FooterComponent.vue";
app.component('FooterComponent', FooterComponent);
import breadcrumbsComponent from "@/components/breadcrumbsComponent.vue";
app.component('breadcrumbsComponent', breadcrumbsComponent);

//sections
import ContactComponent from "@/components/ContactComponent.vue";
app.component('ContactComponent', ContactComponent);
import PhotoDescription from "@/components/PhotoDescription.vue";
app.component('PhotoDescription', PhotoDescription);
import PhotoComponent from "@/components/PhotoComponent.vue";
app.component('PhotoComponent', PhotoComponent);
import OurServices from "@/components/OurServices.vue";
app.component('OurServices', OurServices);
import ServiceOnline from "@/components/ServiceOnline.vue";
app.component('ServiceOnline', ServiceOnline);
import describedComponent from "@/components/describedComponent.vue";
app.component('describedComponent', describedComponent);
import PersonalComponent from "@/components/PersonalComponent.vue";
app.component('PersonalComponent', PersonalComponent);
import OurWorksComponent from "@/components/OurWorksComponent.vue";
app.component('OurWorksComponent', OurWorksComponent);
import GratitudeComponent from "@/components/GratitudeComponent.vue";
app.component('GratitudeComponent', GratitudeComponent);

//custom components
import HamburgerButton from "@/components/custom/HamburgerButton.vue";
app.component('HamburgerButton', HamburgerButton);
import ContactWidget from "@/components/custom/ContactWidget.vue";
app.component('ContactWidget', ContactWidget);
// app.component('SocialNetwork', SocialNetwork);
// import contactForm from "@/components/custom/ContactForm";

app.mount('#app')
