<template>
    <section class="described">
            <div class="wrap">
                <ul class="described__list">
                    <li class="described__item" v-for="(item, i) in info" :key="i">
                        <h3 class="described__title">{{ item.title }}</h3>
                        <p class="described__description">
                            {{item.description}}
                        </p>
                    </li>
                </ul>
            </div>
    </section>
</template>

<script>
export default {
    name: "describedComponent",
    data() {
        return {
            info: [
                {
                    title: 'Наша история',
                    description: 'Стоматологическая клиника «ИРПОЛ» - это качество, доброе отношение и домашний уют. \n' + 'История частной стоматологической практики ведет отсчет с 2001 ода'
                },
                {
                    title: 'Мы ценим Ваше здоровье',
                    description: 'Стоматологическая клиника «ИРПОЛ» предлагает Вам комплексный подход к лечению, \n' + 'протезированию, имплантологии цель которого – решение существующих проблем при \n' + 'максимальном сохранении Ваших зубов, депульпируем зубы строго по показаниям, удаляем \n' + 'только безнадежные зубы.'
                },
                {
                    title: 'Мы гарантируем Вам\n' + 'безопасность лечения ',
                    description: 'Наш персонал с точностью соблюдает все инструкции о дезинфекции и  \n' + 'стерилизации медицинских инструментов.'
                },
                {
                    title: 'Мы бережем Ваше время',
                    description: 'Мы согласовываем с Вами наиболее удобное время для посещения наших специалистов  \n' + 'в стоматологической клинике «Стомарус», стараясь при Вашем желании, проделать \n' + 'максимальный объем работы несколькими специалистами в одно посещение.'
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>