<template>
    <section class="gratitude">
        <div class="wrap">
            <h3 class="gratitude__title">{{ 'Мы ценим, что Вы выбрали нас' }}</h3>
            <p class="gratitude__description">
                Мы сделаем всё, чтобы Ваше лечение в нашей клинике принесло только
                положительные эмоции. Мы обещаем Вам скидки для Ваших детей и Ваших
                родителей
            </p>
            <button class="button button-online">
                Онлайн-запись
            </button>
        </div>
    </section>
</template>

<script>
export default {
    name: "GratitudeComponent"
}
</script>

<style scoped>

</style>