<template>
    <section class="service-online">
        <div class="service-online__bg"></div>
        <div class="wrap">
            <h1 class="service-online__title">
                {{'Наши услуги'}}
            </h1>
            <p>
                Наши квалифицированные специалисты гарантируют высокую <br>
                результативность лечения в сочетании с отличным косметическим эффектом
            </p>
            <button class="button button-online">
                Онлайн-запись
            </button>
        </div>
    </section>
</template>

<script>
export default {
    name: "ServiceOnline"
}
</script>

<style scoped>

</style>