<template>
    <section class="our-works">
        <h2 class="our-works__title">{{ 'Наши работы' }}</h2>
        <div class="our-works__content">
            <swiper class="our-works__slider" v-bind="swiperOptions">
                <swiper-slide class="our-works__slider-item" v-for="(work, i) in works" :key="i">
                    <div class="our-works__item">
                        <div class="our-works__item-img">
                            <img :src="require('@/assets/'+work.icon)" v-if="false">
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
            <div class="our-works__slider-button our-works__slider-next"></div>
            <div class="our-works__slider-button our-works__slider-prev"></div>
        </div>
    </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay'
import { Autoplay, Navigation } from 'swiper/modules';

export default {
    name: "OurWorksComponent",
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            works: [
                {
                    // icon: 'works-1.jpg'
                },
                {
                    // icon: 'works-1.jpg'
                },
                {
                    // icon: 'works-1.jpg'
                },
                {
                    // icon: 'works-1.jpg'
                }
            ],
            swiperOptions:{
                centeredSlides: true,
                slidesPerView: 3,
                loopedSlides: 1,
                spaceBetween: 0,
                autoHeight: false,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false,
                },
                navigation: {
                    prevEl: '.our-works__slider-prev',
                    nextEl: '.our-works__slider-next',
                },
                breakpoints:{
                    0:{
                        slidesPerView: 1,
                        // centeredSlides: false,
                    },
                    1000:{
                        slidesPerView: 2,
                        centeredSlides: false,
                    },
                    1400:{
                        slidesPerView: 3,
                    },
                    // 1380:{
                    //     slidesPerView: 4,
                    // },
                    // 1800:{
                    //     slidesPerView: 5
                    // }
                },
                loop: true,
                observe: true,
                observeParents: true,
                observeSlideChildren: true,
                speed:1000,
                modules:[Autoplay, Navigation]
            }
        }
    }
}
</script>

<style scoped>

</style>