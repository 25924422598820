<template>
    <header class="header">
        <div class="header__bg">
            <div class="wrap header__wrap">
                <a class="header__logo" href="/">
                    <img :src="require('@/assets/logo.svg')" alt="TURNKEY TRADING">
                </a>
                <ul class="header__info-list">
                    <li class="header__info-item" v-for="(item, i) in info" :key="i">
                        <div class="header__info-item__wrap">
                            <div class="header__info-item__badge">
                                <img :src="require('@/assets/'+item.icon)" alt="item.icon">
                            </div>
                            <div class="header__info-item__content">
                                <a v-for="(content, j) in item.content"
                                   :key="j"
                                   :href="content.url"
                                >
                                    {{ content.name }}
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="header__hamburger">
                    <HamburgerButton v-model:active="hamburgerActive"/>
                </div>
                <div class="header__command">
                    <button class="button button-online">{{ ('Онлайн-запись') }}</button>
                </div>
            </div>
        </div>
        <nav class="header__nav nav wrap" :class="[ hamburgerActive && 'header__nav--active']">
            <ul class="nav__list">
                <li class="nav__item" v-for="(item, i) in nav"
                    :key="i"
                    :class="[activeLink == item.url && 'nav__item--active']">
                    <router-link
                        class="nav__item__link"
                        :to="item.url"
                    >
                        {{item.name}}
                    </router-link>
                </li>
            </ul>
            <div class="nav__search">
                <button class="button button-search"></button>
            </div>
        </nav>
    </header>
</template>

<script>

export default {
    name: "HeaderComponent",
    components: {
    },
    data() {
        return {
            hamburgerActive: false,
            search:'',
            nav:[
                {name: 'Главная', url: '/'},
                {name: 'Услуги', url: '/services'},
                {name: 'О клинике', url: '/about-the-clinic'},
                // {name: 'Отзывы', url: '/'},
                {name: 'Контакты', url: '/contact'},
            ],
            info: [
                {
                    icon: 'email.svg',
                    content: [
                        {name: 'mayakdent@gmail.com', 'url': ''}
                    ]
                },
                {
                    icon: 'phone.svg',
                    content: [
                        {name: '+375 17 222-22-22', 'url': '+375 17 222-22-22'},
                        {name: '+375 17 222-22-22', 'url': '+375 17 222-22-22'}
                    ]
                }
            ]
        }
    },
    computed: {
        activeLink() {
            return this.$route.path
        }
    }
}
</script>

<style scoped>

</style>