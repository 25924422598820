<template>
    <section class="section-photo">
        <img :src="bg" alt="">
    </section>
</template>

<script>
export default {
    name: "PhotoComponent",
    props: {
        bg: {
            type: String,
            default: () => require('@/assets/photo-2.png')
        }
    }
}
</script>

<style scoped>

</style>