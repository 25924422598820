<template>
    <section class="photo-description">
        <div class="wrap">
            <h1 class="photo-description__title">
                {{'Лучшие специалисты'}}
            </h1>
            <p class="photo-description__description">
                {{ 'Квалифицированные специалисты гарантируют высокую результативность лечения' }}
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: "PhotoDescription",
    props: {
    },
}
</script>

<style scoped>

</style>