<template>
    <section class="our-services">
        <div class="our-services__bg"></div>
        <h2 class="our-services__title our-services__mobile-title" v-if="value.title">{{ value.title }}</h2>
        <div class="wrap our-services__wrap-photo">
            <div class="our-services__photo">
                <img :src="require('@/assets/'+value.img)" alt="">
            </div>
        </div>
        <div class="wrap our-services__wrap-content">
            <h2 class="our-services__title">{{ value.title }}</h2>
            <div class="our-services__block" v-for="(item, i) in value.info" :key="i">
                <h3 class="our-services__block-title">
                    {{ item.title }}
                </h3>
                <p class="our-services__block-text">
                    {{ item.text }}
                </p>
            </div>
            <button class="button button-base" v-if="value.button">
                {{ 'Прайс' }}
            </button>
        </div>
    </section>
</template>

<script>
export default {
    name: "OurServices",
    props: {
        value: {
            type: [Array, Object]
        }
    },
    data() {
        return{

        }
    }
}
</script>

<style scoped>

</style>