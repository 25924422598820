<template>
    <breadcrumbsComponent/>
    <PhotoComponent/>
    <PersonalComponent/>
    <describedComponent/>
    <OurWorksComponent/>
    <GratitudeComponent/>
</template>

<script>
export default {
    name: "aboutClinicPage",
}
</script>

<style scoped>

</style>