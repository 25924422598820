<template>
    <PhotoDescription/>
    <OurServices class="our-services-bg-1" :value="OurServices[0]"/>
    <OurServices class="our-services-row-revers" :value="OurServices[1]"/>
    <PhotoComponent/>
    <OurServices class="our-services-bg-2 main-page main-page__block-3" :value="OurServices[2]"/>
    <contactComponent/>
</template>

<script>
export default {
    name: "mainPage",
    data() {
        return {
            OurServices: [
                {
                    title: 'Наши услуги',
                    img: 'our-services.jpg',
                    button: {
                        text: 'Прайс'
                    },
                    info: [
                        {
                            title: 'Диагностика',
                            text: 'Компьютерная диагностика, визиография, консультации'
                        },
                        {
                            title: 'Компьютерная томография челюстно-лицевой области',
                            text: '3D снимок'
                        },
                        {
                            title: 'Рентген-Диагностика',
                            text: 'Прицельный рентгеновский снимок'
                        },
                    ]
                },
                {
                    // title: 'Наши услуги',
                    img: 'our-services.jpg',
                    // button: {
                    //     text: 'Прайс'
                    // },
                    info: [
                        {
                            title: 'Терапия',
                            text: 'Лечение периодонтита зубов'
                        },
                        {
                            title: 'Эндодонтия',
                            text: 'Лечение воспаления корней зубов,'
                        },
                        {
                            title: 'Пародонтология',
                            text: 'Лечение пародонтоза, пародонтита десен, гингивита'
                        },
                        {
                            title: 'Хирургия',
                            text: 'Зубосохраняющие операции, Удаление зубов '
                        },
                        {
                            title: 'Ортопедия',
                            text: 'Бюгельные протезы, съемное протезирование, коронки'
                        },
                    ]
                },
                {
                    // title: 'Наши услуги',
                    img: 'our-services-3.jpg',
                    button: {
                        text: 'Прайс'
                    },
                    info: [
                        {
                            title: 'Эстетическая стоматология',
                            text: 'Компьютерная диагностика, визиография, консультации'
                        },
                        {
                            title: 'Гигиена полости рта',
                            text: 'Профессиональная чистка, гигиена полости рта и зубов'
                        },
                    ]
                },
            ]
        }
    }
}
</script>

<style scoped>

</style>