<template>
    <footer class="footer">
        <div class="wrap footer__wrap">
            <ul class="footer__lit footer__lit-1">
                <li class="footer__item">
                    <router-link to="/">
                        <img class="footer__logo" :src="require('@/assets/logo.svg')" alt="logo">
                    </router-link>
                </li>
                <li class="footer__item" v-for="(item, i) in oneMenu" :key="i">
                    <div class="footer__item-content">
                        <img :src="require('@/assets/'+item.icon)" alt="email" v-if="item.icon">
                        <div class="footer__item-content-text">
                            <router-link :to="val.url" v-for="(val, j) in item.value" :key="j">
                                {{ val.name }}
                            </router-link>
                        </div>
                    </div>
                </li>
            </ul>
            <ul class="footer__lit footer__lit-2">
                <li class="footer__item" v-for="(item, i) in twoMenu" :key="i">
                    <div class="footer__item-content">
                        <img :src="require('@/assets/'+item.icon)" alt="email" v-if="item.icon">
                        <div class="footer__item-content-text">
                            <router-link :to="val.url" v-for="(val, j) in item.value" :key="j">
                                {{ val.name }}
                            </router-link>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
    components: {},
    data() {
        return {
            oneMenu:[
                {
                    icon: 'email.svg',
                    value: [
                        {name: 'sibirakova@gmail.com',  url: '',},
                    ]
                },
                {
                    icon: 'phone.svg',
                    value: [
                        {name: '+375 17 ___-__-__',  url: '',},
                        {name: '+375 29 ___-__-__',  url: '',},
                    ]
                }

            ],
            twoMenu:[
                {
                    value: [
                        {name: 'Главная',  url: '/',},
                        {name: 'Услуги',  url: '/',},
                        {name: 'О клинике',  url: '/',},
                        {name: 'Прайс',  url: '/',},
                        {name: 'Услуга',  url: '/',},
                    ]
                },
                {
                    value: [
                        {name: 'Отзывы',  url: '/',},
                        {name: 'Контакты',  url: '/',},
                        {name: 'Услуга',  url: '/',},
                        {name: 'Услуга',  url: '/',},
                        {name: 'Услуга',  url: '/',},
                    ]
                },
            ]
        }
    },
    methods: {
        setLink(link) {
            document.querySelector(link).scrollIntoView({ behavior : "smooth" , block: "center"})
        },
    }
}
</script>

<style scoped>

</style>