<template>
    <section class="breadcrumbs">
        <div class="wrap">
            <ul class="breadcrumbs__list">
                <li v-for="(r, i) in route" :key="i" class="breadcrumbs__item">
                    <router-link :to="r.path" class="breadcrumbs__link">
                        {{ r.title }}
                    </router-link>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    name: "breadcrumbsComponent",
    computed: {
        route() {
            return this.$route.meta?.routeToParent
        }
    }
}
</script>

<style scoped>

</style>