<template>
    <div class="contact-widget">
        <div class="contact-widget__wrap">
            <div class="contact-widget__label">
                <img :src="require('@/assets/label.svg')" alt="logo">
            </div>
            <div class="contact-widget__body">
            <h4 class="contact-widget__title">{{ 'Наши контакты' }}</h4>
            <ul class="contact-widget__list">
                <li class="contact-widget__item" v-for="(item, i) in menu" :key="i">
                    <span class="contact-widget__item-icon">
                        <img :src="require('@/assets/'+item.icon)" alt="item.icon">
                    </span>

                    <div class="contact-widget__item-container">
                        <template v-if="Array.isArray(item.name)">
                            <a v-for="(vl, i) in item.name" :key="i"
                               :href="vl.url"
                               class="contact-widget__item-link"
                            >
                                {{ vl.name }}
                            </a>
                        </template>
                        <a :href="item.url" class="contact-widget__item-link" v-else>
                            {{ item.name }}
                        </a>
                    </div>
                </li>
            </ul>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactWidget",
    data(){
        return {
            menu: [
                {icon: 'point.svg', name: 'Беларусь, г. Минск, ул. К. Туровского 26 (вход со стороны ___)', url: ''},
                {
                    icon: 'phone-contact.svg',
                    name: [
                        {name: ' +375 17 ___-__-__', url: ''},
                        {name: ' +375 17 ___-__-__', url: ''}
                    ]
                },
                {
                    icon: 'email-contact.svg',
                    name: 'sibirakova@gmail.com',
                    url: ''
                },
                {
                    icon: 'instagram.svg',
                    name: '@mayakdent',
                    url: ''
                },
            ]
        }
    }
}
</script>

<style scoped>

</style>